const JSON_PATTERN = /^\{.*}$/;

export const parseEventMessage = data => {
  if (data && typeof data === 'string' && JSON_PATTERN.test(data)) {
    const message = JSON.parse(data);

    if (!message.eventName) return null;

    return message;
  }

  return null;
};

export const isValidOrigin = (origin = '') => {
  const ORIGIN_PATTERN = new RegExp(
    `^https://(\\w+\\.)?${window.location.host}/?`
  );

  return ORIGIN_PATTERN.test(origin);
};
