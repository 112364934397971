import { EventName } from './types';
import { BRIDGE_COOKIE_SESSION_ID_KEY, BRIDGE_STORAGE_KEY } from './config';

class BridgeMessagingAPI {
  static get #origin() {
    return window.location.origin;
  }

  static subscribe(callback) {
    window.addEventListener('message', callback, true);
    return () => window.removeEventListener('message', callback);
  }

  static sendEventToReactNative({ eventName, params }) {
    try {
      if (!window.ReactNativeWebView?.postMessage) {
        return null;
      }

      window.ReactNativeWebView?.postMessage(
        JSON.stringify({ eventName, params })
      );
    } catch (error) {
      console.log(error);
      throw new Error(`Something went wrong during ${eventName} execution`);
    }
  }

  static connecting = () => {
    BridgeMessagingAPI.sendEventToReactNative({
      eventName: EventName.Connecting,
      params: { origin: BridgeMessagingAPI.#origin }
    });
  };

  static handshake = () => {
    BridgeMessagingAPI.sendEventToReactNative({
      eventName: EventName.Handshake,
      params: { origin: BridgeMessagingAPI.#origin }
    });
  };

  static closeWebview() {
    BridgeMessagingAPI.sendEventToReactNative({
      eventName: EventName.CloseWebview,
      params: { origin: BridgeMessagingAPI.#origin }
    });

    localStorage.removeItem(BRIDGE_STORAGE_KEY);
    const expiredDate = new Date(0).toUTCString();
    document.cookie = `${BRIDGE_COOKIE_SESSION_ID_KEY}=; expires=${expiredDate}`;
  }

  static setConfig(config) {
    BridgeMessagingAPI.sendEventToReactNative({
      eventName: EventName.SetConfig,
      params: { ...config, origin: BridgeMessagingAPI.#origin }
    });
  }

  static openUrl(url) {
    BridgeMessagingAPI.sendEventToReactNative({
      eventName: EventName.OpenUrl,
      params: { url, origin: BridgeMessagingAPI.#origin }
    });
  }
}

export default BridgeMessagingAPI;
